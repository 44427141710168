import React, { FunctionComponent } from 'react'
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { default as NextLink } from 'next/link'

import { OldTheme, Scale } from '@modules/core-ui'

const useLinkStyles = makeStyles((theme: OldTheme) => ({
  root: ({ scale }: LinkProps) => ({
    ...theme.select.fontScale(scale),
    cursor: 'pointer',
  }),
}))

type LinkProps = Readonly<{
  className?: MuiLinkProps['className']
  color?: MuiLinkProps['color']

  /**
   * Explicitly set a link to external.  Useful for relative
   * links that need a page load (e.g., downloads)
   */
  external?: boolean
  href?: MuiLinkProps['href']

  /**
   * The target attribute specifies where to open the linked document
   * https://www.w3schools.com/tags/att_a_target.asp
   */
  target?: MuiLinkProps['target']
  /**
   * For next.js dynamic route links.
   * See https://nextjs.org/docs#dynamic-routing
   */
  as?: MuiLinkProps['href']

  /**
   * Typography size
   */
  scale?: Scale
  underline?: MuiLinkProps['underline']
  title?: string
  onClick?: MuiLinkProps['onClick']
  children?: React.ReactNode
}>

/**
 *
 * @deprecated Import it from @react-lib-consumer-pres. Only use for old theming
 */
export const Link: FunctionComponent<LinkProps> = ({ ...props }) => {
  const { target, href, ...muiLinkProps } = props
  const classes = useLinkStyles(props)

  return (
    <NextLink {...{ href, target }} passHref legacyBehavior>
      <MuiLink {...{ classes, target, ...muiLinkProps }} />
    </NextLink>
  )
}
