/** Format any numbers to add dots. E.g: 1234567 > 1.234.567 */
export const formatNumberToString = (num: number): string =>
  (num ?? 0).toLocaleString('en-US')

export type FormatCurrencyOtions = {
  cents?: 'none' | 'hideZero' | 'show'
}

export const formatCurrency = (
  value: number,
  options?: FormatCurrencyOtions,
): string => {
  let { cents = 'show' } = options ?? {}

  if (cents === 'hideZero' && value % 1 === 0) {
    cents = 'none'
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: cents === 'none' ? 0 : 2,
  }).format(value)
}

export const formatPhone = (phone: string): string => {
  //Filter only numbers from the input
  const numbers = (phone ?? '').replaceAll(/\D/g, '')
  if (!numbers) {
    return ''
  }
  const [areaCode, exchangeCode, lineNum] = [
    numbers.slice(0, 3),
    numbers.slice(3, 6),
    numbers.slice(6, 10),
  ]
  const firstPart = `(${areaCode}) ${exchangeCode}`
  return exchangeCode.length === 3
    ? `${firstPart}-${lineNum}`
    : firstPart.trim()
}
