import React, { FC, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material'

import { P } from '@common/react-lib-base'
import { SelectedLoanInfoProps } from '@common/types'
import { OldTheme } from '@modules/core-ui'
import {
  trackAccordionHideDetailsClicked,
  trackAccordionShowDetailsClicked,
} from '@src/tracking'
import { formatCurrency } from '@src/utils/string'

import { CarIcon } from './reskin'

type InformationBoxProps = {
  border?: {
    top?: string
    bottom?: string
    right?: string
    left?: string
  }
  margin: string
}

const Container = styled(Box)(({ theme }: { theme: OldTheme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: 'solid thin',
  borderColor: theme.palette.grey[200],
  borderTop: 'solid thick',
}))

const InformationBox = styled(Box)<{ theme?: OldTheme } & InformationBoxProps>(
  ({ theme, border: { top, right, bottom, left }, margin }) => ({
    borderTop: top,
    borderRight: right,
    borderBottom: bottom,
    borderLeft: left,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin,
    '& h5': {
      color: 'rgba(0, 0, 0, 0.38)',
      margin: 0,
      fontSize: '0.7em',
      fontWeight: 0,
      lineHeight: 'initial',
    },
    '& p': {
      fontSize: '18px',
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
    },
    height: '100%',
    padding: theme.spacing(1),
    lineHeight: '1',
  }),
)

const SubTextDetails = styled(P)({
  color: 'rgba(0, 0, 0, 0.38)',
})

const IconContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 'auto',
  marginRight: '1rem',
  width: '40px',
  height: '40px',

  '& svg': {
    width: '100%',
    height: '100%',
  },
})

export const SelectedLoanInfo: FC<SelectedLoanInfoProps> = ({
  amountFinanced,
  make,
  model,
  monthlyPayment,
  combinedAgreementApr,
  term,
  year,
  trim,
}) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleAccordionChange = (): void => {
    setIsExpanded((prevExpanded) => !prevExpanded)
    if (isExpanded) {
      void trackAccordionHideDetailsClicked()
    } else {
      void trackAccordionShowDetailsClicked()
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <InformationBox
            border={{
              bottom: `solid thin ${theme.palette.grey[200]}`,
            }}
            margin={theme.spacing(0, 1, 0, 1)}
          >
            <Grid container>
              <Grid item xs={12}>
                <h3> {'Your Selected Loan Option'} </h3>
              </Grid>
              <IconContainer item>
                <CarIcon />
              </IconContainer>
              <Grid item md={8} xs={8}>
                <strong> {`${year} ${make} ${model}`} </strong>
                <SubTextDetails>{trim}</SubTextDetails>
              </Grid>
            </Grid>
          </InformationBox>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={isExpanded}
            onChange={handleAccordionChange}
            sx={{
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">
                {isExpanded ? 'Hide Details' : 'Show Details'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={6}>
                  <InformationBox
                    margin={theme.spacing(0, 0, 0, 0)}
                    border={{
                      top: `solid thin ${theme.palette.grey[200]}`,
                      right: `solid thin ${theme.palette.grey[200]}`,
                      left: `solid thin ${theme.palette.grey[200]}`,
                      bottom: `solid thin ${theme.palette.grey[200]}`,
                    }}
                  >
                    <h5> {'Amount Financed'}</h5>
                    <P>{`${formatCurrency(
                      Number.parseInt(amountFinanced),
                    )}`}</P>
                  </InformationBox>
                </Grid>

                <Grid item xs={6}>
                  <InformationBox
                    margin={theme.spacing(0, 0, 0, 0)}
                    border={{
                      top: `solid thin ${theme.palette.grey[200]}`,
                      right: `solid thin ${theme.palette.grey[200]}`,
                      left: `solid thin ${theme.palette.grey[200]}`,
                      bottom: `solid thin ${theme.palette.grey[200]}`,
                    }}
                  >
                    <h5>{'Term'}</h5>
                    <P> {`${term} months`} </P>
                  </InformationBox>
                </Grid>

                <Grid item xs={6}>
                  <InformationBox
                    margin={theme.spacing(0, 0, 0, 0)}
                    border={{
                      top: `solid thin ${theme.palette.grey[200]}`,
                      right: `solid thin ${theme.palette.grey[200]}`,
                      left: `solid thin ${theme.palette.grey[200]}`,
                      bottom: `solid thin ${theme.palette.grey[200]}`,
                    }}
                  >
                    <h5>{'APR'}</h5>
                    <P>
                      {' '}
                      {`${
                        Math.floor(
                          Number.parseFloat(combinedAgreementApr) *
                            Math.pow(10, 2) *
                            Math.pow(10, 2),
                        ) / Math.pow(10, 2)
                      }%`}{' '}
                    </P>
                  </InformationBox>
                </Grid>

                <Grid item xs={6}>
                  <InformationBox
                    margin={theme.spacing(0, 0, 0, 0)}
                    border={{
                      top: `solid thin ${theme.palette.grey[200]}`,
                      right: `solid thin ${theme.palette.grey[200]}`,
                      left: `solid thin ${theme.palette.grey[200]}`,
                      bottom: `solid thin ${theme.palette.grey[200]}`,
                    }}
                  >
                    <h5>
                      {'Monthly Payment'}
                      <sup>*</sup>
                    </h5>
                    <P>{formatCurrency(monthlyPayment)}</P>
                  </InformationBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  )
}
