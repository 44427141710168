import React, { FC, useCallback } from 'react'
import { Box, Button, Card, CardContent, Divider, styled } from '@mui/material'
import Image from 'next/legacy/image'

import {
  OfferSelectionLoanFlexibleOption,
  OfferSelectionLoanLowestOption,
} from '@common/types'
import { formatCurrency } from '@src/utils/string'

import { Text } from '../../reskin'

import {
  CurrentMonthlyPayment,
  Savings,
  SelectCardButton,
} from './offer-selections-components'

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  justifyContent: 'space-between',
}))

const StyledBoxText = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(1),
  background: theme.extension.colors.base.lightSky,
  borderRadius: '4px',
  padding: theme.spacing(0.25, 1),
}))

const FormRowContainer = styled(Box)<{
  paddingTop?: number
  paddingBottom?: number
}>(({ theme, paddingBottom, paddingTop }) => ({
  display: 'block',
  lineHeight: '1.4em',
  padding: theme.spacing(paddingTop ?? 1.75, 0, paddingBottom ?? 1.75),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(paddingTop ?? 1, 0, paddingBottom ?? 1),
  },
}))

const FormRowName = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(0.25),
  ...theme.extension.typography.paragraphs[18],
  color: theme.extension.colors.text.bodyGray,
  [theme.breakpoints.down('sm')]: {
    ...theme.extension.typography.paragraphs[16],
  },
}))

const FormRowValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...theme.extension.typography.subheadings['18m'],
  [theme.breakpoints.down('sm')]: {
    ...theme.extension.typography.subheadings['16m'],
  },
}))

const HeaderDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  color: theme.extension.colors.divider.lightGray,
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(2, 0),
  },
}))

const TermOptionsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0px',
}))

const CardContentStyles = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(0)} !important`,
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  justifyContent: 'space-between',
}))

const StyledCard = styled(Card)<{ selected?: boolean }>(
  ({ selected, theme }) => ({
    display: 'flex',
    maxWidth: '448px',
    borderRadius: '16px',
    border: selected
      ? `4px solid ${theme.extension.colors.base.blueSky}`
      : `4px solid ${theme.extension.colors.text.white}`,
    background: theme.palette.common.white,
    width: '100%',
    boxShadow:
      '0px -4px 20px rgba(0, 0, 0, 0.04), 0px 32px 48px rgba(118, 124, 119, 0.08)',
    fontSize: '1.3rem',
    padding: theme.spacing(3, 4),
    position: 'relative',
    overflow: 'visible',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
  }),
)

const StyledBanner = styled(Box)(({ theme }) => ({
  height: '16px',
  position: 'absolute',
  // Match the parent border radius
  borderRadius: theme.spacing(2, 2, 0, 0),
  maxWidth: '448px',
  width: `calc(100% + ${theme.spacing(1)})`,
  // Subtract the parent margin and border
  marginLeft: `calc(-1 * ${theme.spacing(4)} - 4px)`,
  marginTop: `calc(-1 * ${theme.spacing(3)} - 4px)`,
  background: theme.extension.colors.base.creditKarmaBrandGreen,

  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1, 1, 0, 0),
    // Subtract the parent margin and border
    marginLeft: `calc(-1 * ${theme.spacing(2)} - 4px)`,
    marginTop: `calc(-1 * ${theme.spacing(2)} - 4px)`,
  },
}))

const StyledBannerOverlay = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  height: '12px',
  display: 'block',
  marginTop: '4px',
  position: 'relative',
  // Match the parent border radius
  borderRadius: theme.spacing(2, 2, 0, 0),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
}))

const StyledLogo = styled(Box)(({ theme }) => ({
  float: 'right',
  marginBottom: theme.spacing(-1.5),
  marginTop: theme.spacing(-1),
  marginRight: theme.spacing(-0.5),
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(-0.5),
  },
}))

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'termOptionSelected',
})<{ termOptionSelected?: boolean }>(({ termOptionSelected, theme }) => ({
  borderRadius: 4,
  border: `2px solid ${theme.extension.colors.neutral[700]}`,
  height: '40px',
  fontWeight: 500,
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.extension.colors.base.lightSky,
  marginLeft: theme.spacing(1),
  boxShadow: 'none',
  color: theme.extension.colors.base.blueSky,
  borderColor: theme.extension.colors.base.blueSky,
  minWidth: 'fit-content',
  '&:first-of-type': {
    marginLeft: theme.spacing(0),
  },
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },

  ...(termOptionSelected && {
    backgroundColor: theme.extension.colors.base.blueSky,
    color: theme.extension.colors.base.lightSky,
    borderColor: theme.extension.colors.base.blueSky,

    '&:hover': {
      backgroundColor: theme.extension.colors.base.blueSky,
      color: theme.extension.colors.base.lightSky,
      borderColor: theme.extension.colors.base.blueSky,
    },
  }),
}))

const lowestPaymentSelectCardButtonTestId = 'lowest-payment-select-card-button'

export const LoanLowestOption: FC<OfferSelectionLoanLowestOption> = (props) => {
  const {
    savingPotential,
    amountFinanced,
    monthlyPayment,
    totalPaymentSavings,
    rate,
    term,
    selected,
    onClick,
    id,
    currentMonthlyPayment,
    style,
    annualPercentageRate,
    hasRecommendedTopOffer,
    topOfferReason,
  } = props

  const handleClick = useCallback(() => onClick?.(id), [id, onClick])

  const offerLabel = (): string => {
    if (hasRecommendedTopOffer) {
      return 'Your personalized offer'
    } else if (topOfferReason === 'Combined Savings Ranking') {
      return 'Most Savings'
    } else {
      return 'Lowest Payment'
    }
  }

  return (
    <StyledCard style={style} selected={selected}>
      {hasRecommendedTopOffer && !selected && (
        <StyledBanner>
          <StyledBannerOverlay />
        </StyledBanner>
      )}
      <CardContentStyles>
        <div>
          <HeaderContainer>
            <Text
              use={['h1', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
              color="text.softBlack"
              margin={0}
            >
              {offerLabel()}
            </Text>

            {hasRecommendedTopOffer ? (
              <StyledLogo>
                <Image
                  src="/assets/credit-karma-logo.svg"
                  alt="Credit Karma Logo"
                  width="40"
                  height="40"
                />
              </StyledLogo>
            ) : (
              <StyledBoxText>
                <Text
                  use={['p', { xs: 'subheadings.14m', sm: 'subheadings.14sb' }]}
                  color="base.blueSky"
                  margin={0}
                >
                  {'Most Popular'}
                </Text>
              </StyledBoxText>
            )}
          </HeaderContainer>

          <CurrentMonthlyPayment currentMonthlyPayment={currentMonthlyPayment}>
            {formatCurrency(monthlyPayment, { cents: 'hideZero' })}
          </CurrentMonthlyPayment>

          <HeaderDivider />
          {/* Amount Financed */}
          <FormRowContainer paddingTop={0}>
            <FormRowName>{'Estimated amount financed⁴'} </FormRowName>
            <FormRowValue>{`$${amountFinanced.toLocaleString(
              'en',
            )}`}</FormRowValue>
          </FormRowContainer>

          {/* Select Term */}
          <FormRowContainer>
            <FormRowName>{'Term'}</FormRowName>
            <FormRowValue>{`${term} months`}</FormRowValue>
          </FormRowContainer>

          {/* Annual percentage rate */}
          <FormRowContainer>
            <FormRowName>{'Annual percentage rate'}</FormRowName>
            <FormRowValue>
              <b>
                {`${Number.parseFloat(annualPercentageRate.toString()).toFixed(
                  2,
                )}%`}
              </b>
            </FormRowValue>
          </FormRowContainer>

          {/* Fixed interest rate */}
          <FormRowContainer>
            <FormRowName>Fixed interest rate</FormRowName>
            <FormRowValue>{`${(rate * 100).toLocaleString('en', {
              minimumFractionDigits: 2,
            })}%`}</FormRowValue>
          </FormRowContainer>

          {/* Monthly savings */}
          <FormRowContainer paddingBottom={totalPaymentSavings ? undefined : 3}>
            <FormRowName>{'Monthly savings³'}</FormRowName>
            <FormRowValue>
              <Savings
                savings={Math.abs(savingPotential).toLocaleString('en', {
                  minimumFractionDigits: 2,
                })}
                paymentSavings={savingPotential}
              />
            </FormRowValue>
          </FormRowContainer>

          {/* Estimated total savings */}
          {totalPaymentSavings && (
            <FormRowContainer
              paddingBottom={totalPaymentSavings ? 3 : undefined}
            >
              <FormRowName>{'Estimated total savings'}</FormRowName>
              <FormRowValue>
                <Savings
                  savings={Math.abs(totalPaymentSavings).toLocaleString('en', {
                    minimumFractionDigits: 2,
                  })}
                  paymentSavings={totalPaymentSavings}
                />
              </FormRowValue>
            </FormRowContainer>
          )}
        </div>
        <SelectCardButton
          testId={lowestPaymentSelectCardButtonTestId}
          selected={selected}
          onClick={handleClick}
        />
      </CardContentStyles>
    </StyledCard>
  )
}

const flexibleTermSelectCardButtonTestId = 'flexible-term-select-card-button'

export const LoanFlexibleOption: FC<OfferSelectionLoanFlexibleOption> = (
  props,
) => {
  const {
    amountFinanced,
    selected,
    selectedTerm,
    onTermChange,
    onSelect,
    options = [],
    currentMonthlyPayment,
    style,
  } = props

  const option = options.find((opt) => opt.id === selectedTerm)

  return option ? (
    <StyledCard style={style} selected={selected}>
      <CardContentStyles>
        <div>
          <HeaderContainer>
            <Text
              use={['h1', { xs: 'subheadings.18b', sm: 'subheadings.24b' }]}
              color="text.softBlack"
              margin={0}
            >
              Flexible Term
            </Text>
          </HeaderContainer>

          <CurrentMonthlyPayment currentMonthlyPayment={currentMonthlyPayment}>
            {formatCurrency(option.monthlyPayment, { cents: 'hideZero' })}
          </CurrentMonthlyPayment>

          <HeaderDivider />
          {/* Amount Financed */}
          <FormRowContainer paddingTop={0}>
            <FormRowName>{'Estimated amount financed⁴'}</FormRowName>
            <FormRowValue>{`$${amountFinanced.toLocaleString(
              'en',
            )}`}</FormRowValue>
          </FormRowContainer>

          {/* Select Term (Months) */}
          <FormRowContainer sx={{ py: 1.25 }}>
            <FormRowName>{'Select term'}</FormRowName>
            <FormRowValue>
              <TermOptionsBox>
                {options.map((opt) => (
                  <StyledButton
                    key={opt.id}
                    onClick={() => onTermChange?.(opt.id)}
                    termOptionSelected={opt.id === selectedTerm}
                    variant="contained"
                  >
                    {opt.term}
                  </StyledButton>
                ))}
              </TermOptionsBox>
            </FormRowValue>
          </FormRowContainer>

          {/* Annual percentage rate */}
          <FormRowContainer>
            <FormRowName>{'Annual percentage rate'}</FormRowName>
            <FormRowValue>
              <b>{`${Number.parseFloat(
                option.annualPercentageRate.toString(),
              ).toFixed(2)}%`}</b>
            </FormRowValue>
          </FormRowContainer>

          {/* Fixed interest rate */}
          <FormRowContainer>
            <FormRowName>Fixed interest rate</FormRowName>
            <FormRowValue>
              {option?.rate
                ? `${(option.rate * 100).toLocaleString('en', {
                    minimumFractionDigits: 2,
                  })}%`
                : '_._'}
            </FormRowValue>
          </FormRowContainer>

          {/* Monthly savings */}
          <FormRowContainer
            paddingBottom={option?.totalPaymentSavings ? undefined : 3}
          >
            <FormRowName>{'Monthly savings³'}</FormRowName>
            <FormRowValue>
              <Savings
                savings={Math.abs(option.savingPotential).toLocaleString('en', {
                  minimumFractionDigits: 2,
                })}
                paymentSavings={option.savingPotential}
              />
            </FormRowValue>
          </FormRowContainer>

          {/* Estimated total savings */}
          {option?.totalPaymentSavings && (
            <FormRowContainer
              paddingBottom={option?.totalPaymentSavings ? 3 : undefined}
            >
              <FormRowName>{'Estimated total savings'}</FormRowName>
              <FormRowValue>
                <Savings
                  savings={Math.abs(option.totalPaymentSavings).toLocaleString(
                    'en',
                    {
                      minimumFractionDigits: 2,
                    },
                  )}
                  paymentSavings={option.totalPaymentSavings}
                />
              </FormRowValue>
            </FormRowContainer>
          )}
        </div>

        <SelectCardButton
          testId={flexibleTermSelectCardButtonTestId}
          selected={selected}
          onClick={onSelect}
        />
      </CardContentStyles>
    </StyledCard>
  ) : null
}
